import React from 'react';
import AppContainer from "./containers/AppContainer";

import { CookiesProvider } from 'react-cookie';

import './App.css';

function App() {
  return (
    <CookiesProvider>
      <AppContainer />
    </CookiesProvider>
  );
}

export default App;