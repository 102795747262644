import React from 'react'

import i18n from '../i18n';

class Contact extends React.Component {
  render() {
    return (
      <div className="main-container">
        <h1>{i18n.t("contact")}</h1>
        <h3>{i18n.t("in-construction")}</h3>
      </div>
    )
  }
}

export default Contact;