import React from "react";

import i18n from '../i18n';

class RecipeSummary extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true
    };
  }

  componentDidMount() {

    this.fetchRecipe();
  }

  setErrorState(error) {

    this.setState({
      loading: false,
      recipe: null,
      error
    });

  }

  fetchRecipe() {

    const selections = this.props.selections;

    console.log(selections);

    let query = "?app_key=bbfb4898e5859711cb3f4d5136dc6cb6&app_id=2a6cd6ae";

    if (selections && selections.length > 0) {

      selections.forEach(selection => {

        if (!selection.skipped) {
          switch (selection.category) {
            // case "meal type":
            //   query += "&type=" + selection.name;
            //   break;
            // case "cuisine":
            //   query += "&cuisine=" + selection.name;
            //   break;
            case "ingredients":
              query += "&q=" + selection.apiParameter;
              break;
            case "diet":
              query += "&health=" + selection.apiParameter;
              break;
            case "intolerances":
              query += "&health=" + selection.apiParameter;
              break;
            case "duration":
              query += "&time=" + selection.apiParameter;
              break;
            case "calories":
              query += "&calories=" + selection.apiParameter;
              break;
            default:
              query += "";
          }
        }

      });
    }

    // if no ingredient was selected, set the q mandatory parameter with a random ingredient
    if (query.indexOf("&q=") === -1) {
      let randomIngredients = ["chicken", "avocado", "tomato", "cheese", "egg", "garlic"];
      query += "&q=" + randomIngredients[Math.floor(Math.random() * randomIngredients.length)]; // TODO: change to select random ingredient
    }

    console.log(query);

    if (this.state.totalResults && this.state.totalResults > 0) {

      let fixedTotalResults = this.state.totalResults > 100 ? 100 : this.state.totalResults;
      let randomOffset = Math.floor(Math.random() * fixedTotalResults);

      // TODO: check repeated recipes

      console.log("Only one call with offset " + randomOffset);

      // do a second call to get a random recipe from the total
      fetch("https://api.edamam.com/search" + query + "&from=" + randomOffset + "&to=" + (randomOffset + 1))
        .then(res => res.json())
        .then(
          (response) => {

            console.log(response);

            this.processResponse(response);

          },
          (error) => {
            this.setErrorState(error);
          }
        )

    } else {

      console.log("First api call.");

      // do a first call to get the total number of possible results.
      fetch("https://api.edamam.com/search" + query + "&from=0&to=1")
        .then(res => res.json())
        .then(
          (response) => {

            console.log(response);

            if (response.count === undefined) {
              this.setErrorState(response);
              return;
            }

            if (response.count > 0) {

              // TODO: check logic with pages
              let fixedTotalResults = response.count > 100 ? 100 : response.count;
              let randomOffset = Math.floor(Math.random() * fixedTotalResults);

              console.log("Second api call with offset " + randomOffset);

              // do a second call to get a random recipe from the total
              fetch("https://api.edamam.com/search" + query + "&from=" + randomOffset + "&to=" + (randomOffset + 1))
                .then(res => res.json())
                .then(
                  (response) => {

                    console.log(response);

                    this.processResponse(response);

                  },
                  (error) => {
                    this.setErrorState(error);
                  }
                )
            } else {
              // TODO: show error if there are no results
            }
          },
          (error) => {
            this.setErrorState(error);
          }
        )
    }
  }

  processResponse(response) {

    if (response.hits === undefined) {
      this.setErrorState(response);
      return;
    }

    let recipeSummary = {};

    if (response && response.hits && response.hits[0]) {
      recipeSummary = response.hits[0].recipe;
    } else {
      // TODO: show no results found
      alert("no results found");
    }

    this.setState({
      totalResults: response.count,
      recipeTitle: recipeSummary.label,
      recipeImg: recipeSummary.image,
      loading: false,
      recipe: {}
    });
  }

  viewRecipeDetails() {
    this.setState({
      viewRecipeDetails: true
    })
  }

  tryAgain() {

    this.setState({
      loading: true,
      recipe: null,
      viewRecipeDetails: false
    });

    this.fetchRecipe();
  }

  renderLoading() {
    return <div className="loader-container">
      {/* <img alt="test" src="images/loaders/loader-3.gif" /> */}
      <div className="lds-ripple"><div></div><div></div></div>
      <div>{i18n.t("mixing-ingredients")}</div>
    </div>;
  }

  renderError() {
    return <div className="recipe-summary-main-container">
      <div className="error-container">{i18n.t("api-error-message")}</div>
      <div className="try-again-container"><button onClick={() => this.tryAgain()}>{i18n.t("try-again")}</button></div>
    </div>;
  }

  renderRecipeSummary() {
    return (

      <div className="recipe-details-root-container">

        <div className="recipe-details-main-container">

          <div className="recipe-details-container">

            <div className="recipe-first-row">

              <div className="recipe-img-container">

                <div className="recipe-details-header-container">
                  <div className="recipe-name">{this.state.recipeTitle}</div>
                </div>

                <img alt="test" src={this.state.recipeImg} className="recipe-img-details" />

                <div className="add-to-my-meals-container">
                  <button>{i18n.t("add-to-my-meals")}</button>
                </div>

              </div>

            </div>

            <div className="recipe-summary-info-container">
              <div>
                <img alt="test" src="images/icons/timer.svg" />
                <div className="ingregient-info-label">5-10</div>
              </div>
              <div>
                <img alt="test" src="images/icons/easy.svg" />
                <div className="ingregient-info-label">easy</div>
              </div>
              <div>
                <img alt="test" src="images/icons/calories.svg" />
                <div className="ingregient-info-label">400 kcal</div>
              </div>
              <div>
                <img alt="test" src="images/icons/3-group.svg" />
                <div className="ingregient-info-label">serves 3</div>
              </div>
            </div>

            {
              this.state.viewRecipeDetails &&                

              <div className="recipe-second-row">

                <div className="recipe-ingredients-container">
                  <div className="recipe-ingredients-header">Ingredients</div>
                  <div className="recipe-ingredients">
                    <div className="recipe-ingredient-container">
                      <div>
                        <div className="ingredient-circle">
                        </div>
                      </div>
                      <div>
                        375g pasta shells
                  </div>
                    </div>
                    <div className="recipe-ingredient-container">
                      <div>
                        <div className="ingredient-circle">
                        </div>
                      </div>
                      <div>
                        500g No Added Hormones Australian Beef Sausage with Herbs & Garlic, casings removed
                  </div>
                    </div>
                    <div className="recipe-ingredient-container">
                      <div>
                        <div className="ingredient-circle">
                        </div>
                      </div>
                      <div>
                        200g cup mushrooms, sliced
                  </div>
                    </div>
                    <div className="recipe-ingredient-container">
                      <div>
                        <div className="ingredient-circle">
                        </div>
                      </div>
                      <div>
                        500g btl Mum’s Sause Bolognese
                  </div>
                    </div>
                    <div className="recipe-ingredient-container">
                      <div>
                        <div className="ingredient-circle">
                        </div>
                      </div>
                      <div>
                        60g pkt Australian Baby Spinach
                  </div>
                    </div>
                    <div className="recipe-ingredient-container">
                      <div>
                        <div className="ingredient-circle">
                        </div>
                      </div>
                      <div>
                        Flat-leaf parsley leaves, to serve
                  </div>
                    </div>
                    <div className="recipe-ingredient-container">
                      <div>
                        <div className="ingredient-circle">
                        </div>
                      </div>
                      <div>
                        Shaved parmesan, to serve
                  </div>
                    </div>
                  </div>
                </div>

              </div>
            }

            {
              !this.state.viewRecipeDetails &&
              <div className="recipe-third-row">
                <button onClick={() => this.viewRecipeDetails()} className="view-full-recipe-button">{i18n.t("view-full-recipe")}</button>
              </div>              
            }
          </div>
        </div>

        <div className="try-again-container"><button onClick={() => this.tryAgain()}>{i18n.t("try-again")}</button></div>

      </div>
    );
  }

  render() {

    console.log(this.state);

    if (this.state.loading) {
      return this.renderLoading();
    } else if (this.state.recipe) {
      return this.renderRecipeSummary();
    } else {
      return this.renderError();
    }
  }
}

export default RecipeSummary;