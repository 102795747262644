import stepsList from "../stepsList.json";

import i18n from '../i18n';

export default function filterOptions(searchText, stepNumber, maxResults) {
  return stepsList[stepNumber].options.slice(4)
    .filter(stepOption => {
      if (stepOption["name-" + i18n.language].normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(searchText.toLowerCase())) {
        return true;
      }
    //   if (stepOption.keywords.includes(searchText)) {
    //     return true;
    //   }
      return false;
    })
    .slice(0, maxResults);
}