import React from "react";

import i18n from '../i18n';

import stepsList from "../stepsList.json";

import WizardStep from "./WizardStep";
import RecipeSummary from "./RecipeSummary";
import Selections from "./Selections";

import './Wizard.css';

class Wizard extends React.Component {
  
  constructor(props) {
    
    super(props);

    this.state = this.getInitialState();

    this.selectWizardOption = this.selectWizardOption.bind(this);
    this.skipWizardOption = this.skipWizardOption.bind(this);
    this.randomizeWizardOption = this.randomizeWizardOption.bind(this);
    this.editSelection = this.editSelection.bind(this);
  }

  goToFristScreen() {

    this.setState(this.getInitialState());
  }

  getInitialState() {

    return {
      currentStepNumber: 0,
      selectedIngredients: [],
      currentStepIngredients: null,
      revealRecipe: false,
      showInitOptions: true,
      showRandomRecipe: false
    };
  }

  selectWizardOption(selectedOption, skipOption) {

    const currentStepNumber = this.state.currentStepNumber;

    // Get next set of ingredients based on previous selection.

    let selectedIngredients = this.state.selectedIngredients;

    selectedOption["category-en"] = stepsList[currentStepNumber]["category-en"];
    selectedOption["category-es"] = stepsList[currentStepNumber]["category-es"];
    selectedOption.skipped = skipOption;

    selectedIngredients.splice(currentStepNumber, 1, selectedOption);

    const nextStepNumber = currentStepNumber + 1;

    const currentStepIngredients = stepsList[nextStepNumber];

    this.setState({
      currentStepNumber: nextStepNumber,
      selectedIngredients: selectedIngredients,
      currentStepIngredients: currentStepIngredients,
      revealRecipe: false
    });

  };

  skipWizardOption() {

    this.selectWizardOption({}, true);
  }

  randomizeWizardOption(stepInfo) {

    let options = stepInfo.options;
    var option = options[Math.floor(Math.random() * options.length)];

    this.selectWizardOption(option);
  }

  editSelection(stepNumber) {

    const currentStepIngredients = stepsList[stepNumber];

    this.setState({
      currentStepNumber: stepNumber,
      currentStepIngredients: currentStepIngredients,
      revealRecipe: false
    });
  }

  startWizard() {
    this.setState({
      currentStepNumber: 0,
      selectedIngredients: [],
      currentStepIngredients: stepsList[0],
      revealRecipe: false,
      showInitOptions: false,
      showRandomRecipe: false
    });
  }

  goToPreviousStep() {

    const selectedIngredients = this.state.selectedIngredients.slice(0, this.state.selectedIngredients.length - 1);

    const currentStepNumber = selectedIngredients.length;
    const currentStepIngredients = stepsList[currentStepNumber];

    this.setState({
      currentStepNumber: currentStepNumber,
      selectedIngredients: selectedIngredients,
      currentStepIngredients: currentStepIngredients,
      revealRecipe: false
    });
  }

  chooseRandomRecipe() {
    this.setState({
      revealRecipe: true,
      showRandomRecipe: true,
      showInitOptions: false
    });
  }

  revealRecipe() {
    this.setState({
      revealRecipe: true
    });
  }

  render() {

    //console.log(this.state);
    //console.log(this.props);

    const selectedIngredients = this.state.selectedIngredients;
    const currentStepIngredients = this.state.currentStepIngredients;
    const currentStepNumber = this.state.currentStepNumber;

    let startAgainButton;
    
    if (selectedIngredients.length || this.state.revealRecipe) {
      let startAgainButtonText = selectedIngredients.length ? i18n.t("start-again") : i18n.t("let-me-choose")
      startAgainButton = <button className="start-again-button" onClick={() => this.startWizard()}>{startAgainButtonText}</button>;
    }

    let recipeContainer;

    if (this.state.revealRecipe || this.state.showRandomRecipe) {
      recipeContainer = <RecipeSummary selections={selectedIngredients} />;
    }

    let revealbutton;
    if ((selectedIngredients.length || stepsList.length === currentStepNumber) && !recipeContainer) {
      revealbutton = 
      <div className="reveal-button-continer">
        <div className={ stepsList.length === currentStepNumber ? "reveal-style wobble-hor-bottom" : "reveal-style"} onClick={() => this.revealRecipe()}>
        {i18n.t("reveal-recipe")}
        </div>
      </div>
    }

    let initOptions;
    if (this.state.showInitOptions) {
      initOptions = <div className="first-screen-container">
                      <div className="logo-description-container">
                        <div className="big-logo-container">Devoroo</div>
                        <div className="site-description-container">
                          {i18n.t("site-description")}
                        </div>
                      </div>
                      <div className="first-choice-question">
                        {i18n.t("first-choice-question")}
                      </div>
                      <div className="first-choice-buttons">
                        <button className="let-me-choose" onClick={() => this.startWizard()}>
                          {i18n.t("let-me-choose")}
                          </button>
                        <button className="surprise-me" onClick={() => this.chooseRandomRecipe()}>
                          {i18n.t("surprise-me")}
                        </button>
                      </div>
                    </div>
    }

    let wizardSteps = null;
    let selections =  null;
    if (currentStepIngredients && !recipeContainer) {
      wizardSteps = <WizardStep stepNumber={currentStepNumber} currentStepSelections={currentStepIngredients} 
                                goToPreviousStep={() => this.goToPreviousStep()}
                                selectWizardOption={(option) => this.selectWizardOption(option)}
                                skipWizardOption={() => this.skipWizardOption()}
                                randomizeWizardOption={(stepInfo) => this.randomizeWizardOption(stepInfo)}/>
    }

    if (currentStepIngredients || (!currentStepIngredients && selectedIngredients.length)) {
      selections = <Selections selections={selectedIngredients} editSelection={(stepNumber) => this.editSelection(stepNumber)} />
    }

    return (
      <div className="wizard-container">
        <div className="buttons-spacer-top"></div>

        {selections}

        {initOptions}

        {recipeContainer}

        {wizardSteps}

        {revealbutton}

        {/* {backButton} */}
        {startAgainButton}

        <div className="buttons-spacer-bottom"></div>
      </div>
    );
  }
}

export default Wizard;