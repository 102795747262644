import React from 'react'

import i18n from '../i18n';

class WizardOption extends React.Component {
  
  render() {
    return (
      <div onClick={() => this.props.selectWizardOption(this.props.ingredient, this.props.category)}>
        <img alt="test" src={this.props.ingredient.imgSrc}/>
        <div className="option-name">{this.props.ingredient["name-" + i18n.language]}</div>
      </div>
    );
  }
}

export default WizardOption;