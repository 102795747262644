import React from 'react'

import i18n from '../i18n';

import Selection from "./Selection";

class Selections extends React.Component {
  render() {
    return (
      this.props.selections && this.props.selections.length ?

        <div className="selections-main-container">
          <div className="selections-header">{i18n.t("my-selections")}</div>
          <div className="selections-container">
            {
              this.props.selections.map((selection, index) => {
                return <Selection key={index} selection={selection} stepNumber={index} editSelection={this.props.editSelection} />
              })
            }
          </div>
        </div>
        :
        null
    );
  }
}

export default Selections;