import React from 'react'
import WizardOption from './WizardOption'
import filterOptions from "./filterOptions";

import i18n from '../i18n';

class WizardStep extends React.Component {

  constructor(props) {

    super(props);

    this.textInput = React.createRef();

    this.state = {
      filterInput: '',
      moreOptionsOpen: false,
      stepNumber: this.props.stepNumber,
      filteredOptions: this.props.currentStepSelections.options.slice(4)
    };

    this.openCloseMoreOptions = this.openCloseMoreOptions.bind(this);
  }

  openCloseMoreOptions() {

    this.textInput.current.focus();

    this.setState({
      moreOptionsOpen: !this.state.moreOptionsOpen
    });
  }

  handleSearchChange = event => {
    this.setState({
      filterInput: event.target.value,
      filteredOptions: filterOptions(event.target.value, this.props.stepNumber, 20)
    });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.stepNumber !== nextProps.stepNumber) {
      return {
        filterInput: '',
        moreOptionsOpen: false,
        stepNumber: nextProps.stepNumber,
        filteredOptions: nextProps.currentStepSelections.options.slice(4)
      }
    }
    return null
  }

  render() {

    //console.log(this.state);

    let questionContainer;
    let bottomButtons;

    let stepOptions = this.props.currentStepSelections.options;
    let stepQuestion = this.props.currentStepSelections["question-" + i18n.language];
    let stepCategory = this.props.currentStepSelections["category-" + i18n.language];

    if (stepOptions.length) {
      questionContainer = <div className="selection-question">{this.state.stepNumber + 1}. {stepQuestion}</div>
      bottomButtons = <div className="wizard-step-bottom-buttons-container">
        {this.state.stepNumber !== 0 && <button className="back-button" onClick={() => this.props.goToPreviousStep()}>{i18n.t("back")}</button>}
        <button className="skip-button" onClick={() => this.props.skipWizardOption(stepCategory)}>{i18n.t("skip-option")}</button>
      </div>
    }

    return (
      <div className="wizard-step-container">

        {questionContainer}

        <div className="ingredients-container">
          {
            stepOptions.slice(0, 4).map((option, index) =>
              <WizardOption key={index} category={stepCategory} ingredient={option} selectWizardOption={this.props.selectWizardOption} />
            )
          }
        </div>

        {
          stepOptions.length > 4
          &&
          <div className="more-main-container">
            <div className="more-button-container" onClick={() => this.openCloseMoreOptions()}>
              <div>{i18n.t("more-options")}</div>
              <img alt="test" src={this.state.moreOptionsOpen ? "images/icons/more_arrow_up-24px.svg" : "images/icons/more_arrow_down-24px.svg"} />
            </div>
            <div className={this.state.moreOptionsOpen ? "more-options-container" : "more-options-container more-options-closed"}>
              <div>
                <input ref={this.textInput} value={this.state.filterInput} onChange={this.handleSearchChange} type="text" className="search-option-input" placeholder={i18n.t("more-options-search")} />

                <div className="ingredients-container">
                  {
                    this.state.filteredOptions.map((option, index) =>
                      <WizardOption key={index} category={stepCategory} ingredient={option} selectWizardOption={this.props.selectWizardOption} />
                    )
                  }
                </div>

              </div>
            </div>
          </div>
        }

        {bottomButtons}

        <div className="wizard-step-bottom-buttons-container">
          <button className="choose-option-for-me" onClick={() => this.props.randomizeWizardOption(this.props.currentStepSelections)}>{i18n.t("choose-for-me")}</button>
        </div>
        
      </div>
    );
  }
}

export default WizardStep;