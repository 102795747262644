import React from 'react'

import i18n from '../i18n';

class Selection extends React.Component {

  render() {
    
    const selection = this.props.selection;

    return (
      <div className="selection-main-container" onClick={() => this.props.editSelection(this.props.stepNumber)}>
        <div className="selection-category">{selection["category-" + i18n.language]}</div>
        <div className={ selection.skipped ? "selection-container selection-skipped" : "selection-container"}>
          <img alt="test" src={ selection.skipped ? "images/icons/question.svg" : selection.imgSrc }/>
          <div className="selection-name">
            { selection.skipped ? i18n.t("any") : selection["name-" + i18n.language] }
          </div>
        </div>
      </div>
    );
  }
}

export default Selection;