import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(LanguageDetector).init({
  // we init with resources
  resources: {
    en: {
      translations: {
        "site-description": "Quick meal suggestions a few clicks away.",
        "first-choice-question": "What are you craving?",
        "let-me-choose": "let me choose",
        "surprise-me": "surprise me!",
        "in-construction": "In Construction...",
        "contact": "Contact",
        "about": "About",
        "policy": "Privacy policy",
        "my-meals": "My Meals",
        "dish-of-the-day": "Dish Of The Day",
        "log-in": "Log In",
        "sign-out": "Sign Out",
        "skip-option": "skip option",
        "choose-for-me": "choose for me",
        "reveal-recipe": "REVEAL RECIPE",
        "back": "back",
        "start-again": "start again",
        "more-options": "more options",
        "more-options-message": "More options will go here...",
        "more-options-search": "Enter an option here...",
        "any": "any",
        "mixing-ingredients": "Mixing ingredients...",
        "try-again": "Meh... try again",
        "view-full-recipe": "View full recipe",
        "api-error-message": "Sorry! Something went wrong. Please give it a minute and try again.",
        "my-selections": "My Selections",
        "add-to-my-meals": "add to my meals",
        "cookies-consent-text": "This website uses cookies to ensure you get the best experience.",
        "cookies-consent-button": "allow cookies",
      }
    },
    es: {
      translations: {
        "site-description": "Sugerencias rápidas de recetas con sólo unos clicks.",
        "first-choice-question": "Algún antojo para hoy?",
        "let-me-choose": "quiero elegir",
        "surprise-me": "sorprendeme!",
        "in-construction": "En Construcción...",
        "contact": "Contacto",
        "about": "Acerca de",
        "policy": "Política de privacidad",
        "my-meals": "Mis Comidas",
        "dish-of-the-day": "Plato Del Día",
        "log-in": "Ingresar",
        "sign-out": "Cerrar Sesión",
        "skip-option": "saltar opción",
        "choose-for-me": "elegir por mi",
        "reveal-recipe": "REVELAR RECETA",
        "back": "atrás",
        "start-again": "empezar de nuevo",
        "more-options": "más opciones",
        "more-options-message": "Acá irán más opciones...",
        "more-options-search": "Ingresá una opción acá...",
        "any": "cualquiera",
        "mixing-ingredients": "Mezclando ingredientes...",
        "try-again": "Nah... probar otra",
        "view-full-recipe": "Ver receta completa",
        "api-error-message": "Perdón! Algo anda mal. Esperá unos minutos y probá de nuevo.",
        "my-selections": "Mis Selecciones",
        "add-to-my-meals": "agregar a mis comidas",
        "cookies-consent-text": "Este sitio usa cookies para asegurar que tengas la mejor experiencia.",
        "cookies-consent-button": "permitir cookies",
      }
    }
  },
  fallbackLng: "en",
  debug: false,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ","
  },

  react: {
    wait: true
  }
});

export default i18n;