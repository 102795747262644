import React from 'react'

import { instanceOf } from 'prop-types';
import i18n from '../i18n';

import { withCookies, Cookies } from 'react-cookie';

import { Route, Link, BrowserRouter as Router, Switch } from 'react-router-dom'

import Wizard from '../components/Wizard';
import Contact from '../components/Contact'
import About from '../components/About'
import Policy from '../components/Policy'
//import DishOfTheDay from '../components/DishOfTheDay'
//import Meals from '../components/Meals'

//import Amplify, { Auth } from 'aws-amplify';
//import awsconfig from '../aws-exports';

//import { Authenticator, SignIn, SignUp, ConfirmSignUp, RequireNewPassword, ForgotPassword } from 'aws-amplify-react';

//Amplify.configure(awsconfig);

/*
const authCustomTheme = {
  button: { backgroundColor: '#7dffbc', fontWeight: 'bold', border: '2px solid #1efc89', color: 'black' },
  a: { color: '#267c50', fontWeight: 'bold' },
  toast: { position: 'unset', width: '100vw' },
}

const signUpConfig = {
  hideAllDefaults: true,
  signUpFields: [
    {
      label: 'Email',
      key: 'username',
      required: true,
      placeholder: 'Email',
      type: 'email',
      displayOrder: 1,
    },
    {
      label: 'Password',
      key: 'password',
      required: true,
      placeholder: 'Password',
      type: 'password',
      displayOrder: 2,
    },
  ],
}

class AuthComponent extends React.Component {

  handleStateChange = state => {
    
    //console.log(state);
    
    if (state === 'signedIn') {
      this.props.onUserSignIn();
    } else if (state ==='signIn') {
      this.props.onUserSignOut();
    }
  };

  render() {
    return (
      <div className="auth-container">
        <Authenticator hideDefault={true} 
          onStateChange={this.handleStateChange} 
          theme={authCustomTheme} >
          <SignIn />
          <RequireNewPassword />
          <SignUp signUpConfig={signUpConfig} />
          <ConfirmSignUp />
          <ForgotPassword />
          <Loading />
        </Authenticator>
      </div>
    );
  }
}

const ProtectedRoute = ({ render: C, props: childProps, ...rest }) => (
  <Route
    {...rest}
    render={rProps =>
      childProps.isLoggedIn ? (
        <C {...rProps} {...childProps} />
      ) : (
          <Redirect to={`/auth?redirect=${rProps.location.pathname}${rProps.location.search}`} />
        )
    }
  />
);

const ProppedRoute = ({ render: C, props: childProps, ...rest }) => (
  <Route {...rest} render={rProps => <C {...rProps} {...childProps} />} />
);
*/

const Routes = ({ childProps }) => (
  <Switch>
    <Route exact path="/" component={Wizard} />
    {/* <Route exact path="/home" component={Wizard} /> */}
    {/* <Route path="/meals/:filter" component={Meals} /> */}
    {/* <Route path="/dishoftheday" component={DishOfTheDay} /> */}
    <Route path="/contact" component={Contact} />
    <Route path="/about" component={About} />
    <Route path="/policy" component={Policy} />

    {/* <ProppedRoute
      exact
      path="/auth"
      render={AuthComponent}
      props={childProps}
    /> */}

    {/* <ProtectedRoute
      exact
      path="/meals"
      render={Meals}
      props={childProps}
    /> */}
  </Switch>
);

class AppWithRouter extends React.Component {

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);

    const { cookies } = props;

    // TODO: move to function init auth state
    /*
    Auth.currentAuthenticatedUser()
      .then(user => {

        if (user) {
          this.setState({ authState: { isLoggedIn: true } });
        }

      }).catch(err => {

        //console.log(err);

        this.setState({ authState: { isLoggedIn: false } });
      });
    */

    let darkModeOn = cookies.get('darkMode');
    let language = cookies.get('language');
    if (language === undefined) {
      cookies.set('language', 'en');
      i18n.changeLanguage('en');
    }

    let cookiesConsentAccepted = cookies.get('cookiesConsentAccepted');

    this.state = {
      // authState: {
      //   isLoggedIn: false
      // },
      english: language === undefined || language === 'en',
      darkMode: darkModeOn !== undefined && darkModeOn === 'true',
      cookiesConsentAccepted: cookiesConsentAccepted !== undefined && cookiesConsentAccepted === 'true'
    };
  }

  /*
  handleUserSignIn = () => {
    this.setState({ authState: { isLoggedIn: true } });
  };

  handleUserSignOut = () => {
    this.setState({ authState: { isLoggedIn: false } });
  }

  signOut = () => {

    Auth.signOut()
      .then(data => {
        //console.log("Signed out.");
        //this.setState({ authState: { isLoggedIn: false } });
      })
      .catch(err => {
        //console.log("Error signing out.");
      })
  }
  */

  changeLanguage = () => {

    const english = !this.state.english;
    const language = english ? 'en' : 'es';

    const { cookies } = this.props;
    cookies.set('language', language);
    
    this.setState({ english: english });
    i18n.changeLanguage(language);
  }

  toggleColorMode = () => {

    const { cookies } = this.props;

    const darkModeOn = !this.state.darkMode;

    cookies.set('darkMode', darkModeOn);

    this.setState({ darkMode: darkModeOn });
  }

  acceptCookiesConsent = () => {

    const { cookies } = this.props;

    cookies.set('cookiesConsentAccepted', 'true');
    
    this.setState({ cookiesConsentAccepted: 'true' });
  }

  goHome = () => {
    this.wizardElement.current.goToFristScreen();
  }

  render() {

    //console.log(this.state);
    
    /*
    const childProps = {
      isLoggedIn: this.state.authState.isLoggedIn,
      onUserSignIn: this.handleUserSignIn,
      onUserSignOut: this.handleUserSignOut
    };
    */

    const childProps = {};

    return (
      <div className={ this.state.darkMode ? "app-container dark-mode" : "app-container"}>
        <div className="app-header">
          <div className="left-logo">
            <a href="/">Devoroo</a>
          </div>
          {/* <div className="login-buttons-container">
            <Link to="/dishoftheday">{i18n.t("dish-of-the-day")}</Link>
            {this.state.authState.isLoggedIn ? <Link to="/meals">{i18n.t("my-meals")}</Link> : <Link to="/auth">{i18n.t("log-in")}</Link>}
            {this.state.authState.isLoggedIn ? <a href="/" onClick={() => this.signOut()}>{i18n.t("sign-out")}</a> : null}
          </div> */}
        </div>
        
        <div className="app-background">
          <img alt="test" className="bg-image-top-left" src="images/background-icons/fish.svg" />
          <img alt="test" className="bg-image-top-right" src="images/background-icons/cheese.svg" />
          <img alt="test" className="bg-image-bottom-left" src="images/background-icons/avocado.svg" />
          <img alt="test" className="bg-image-bottom-right" src="images/background-icons/fried-egg.svg" />
          {/* <img className="bg-image-1" src="images/background-icons/carrot.svg" />
          <img className="bg-image-1" src="images/background-icons/watermelon.svg" /> */}

          {/* <img className="bg-image-1" src="images/background-icons/pumpkin.svg" />
          <img className="bg-image-2" src="images/background-icons/fish.svg" />
          <img className="bg-image-3" src="images/background-icons/asparagus.svg" />
          <img className="bg-image-4" src="images/background-icons/clove-garlic.svg" />
          <img className="bg-image-5" src="images/background-icons/lemon.svg" />
          <img className="bg-image-6" src="images/background-icons/cauliflower.svg" />
          <img className="bg-image-7" src="images/background-icons/hamburguer.svg" />
          <img className="bg-image-8" src="images/background-icons/carrot.svg" />
          <img className="bg-image-9" src="images/background-icons/fried-egg.svg" />
          <img className="bg-image-10" src="images/background-icons/banana.svg" />
          <img className="bg-image-11" src="images/background-icons/croissant.svg" />
          <img className="bg-image-12" src="images/background-icons/meat.svg" />
          <img className="bg-image-13" src="images/background-icons/melon.svg" />
          <img className="bg-image-14" src="images/background-icons/pear.svg" />
          <img className="bg-image-15" src="images/background-icons/pizza-slice.svg" />
          <img className="bg-image-16" src="images/background-icons/sushi.svg" />
          <img className="bg-image-17" src="images/background-icons/watermelon.svg" />
          <img className="bg-image-18" src="images/background-icons/chicken-leg.svg" />
          <img className="bg-image-19" src="images/background-icons/ice-cream.svg" /> */}
        </div>
        
        {/* TODO: check to redirect for requested page */}
        {/* { this.state.authState.isLoggedIn ? <Redirect to="/" /> : null } */}

        <Routes childProps={childProps} />

        { !this.state.cookiesConsentAccepted &&
          <div className="cookies-consent">
            {/* {i18n.t("cookies-consent-header")} */}
            <div>
              {i18n.t("cookies-consent-text")}
            </div>
            <button onClick={() => this.acceptCookiesConsent()}>{i18n.t("cookies-consent-button")}</button>
          </div>
        }

        <div className="app-footer">
          <div className="app-footer-left-container">
            <div>
              <Link to="/contact">{i18n.t("contact")}</Link>
            </div>
            <div>
              <Link to="/about">{i18n.t("about")}</Link>
            </div>
            <div>
              <Link to="/policy">{i18n.t("policy")}</Link>
            </div>
          </div>
          

          <div className="app-footer-right-container">

            <img alt="test" src={ this.state.english ? "images/icons/great-britain.svg" : "images/icons/spain.svg"}  className="lang-img" onClick={() => this.changeLanguage()}/>
            <label className="switch">
              <input type="checkbox" onClick={() => this.toggleColorMode()} defaultChecked={this.state.darkMode} /> />
              <span className="slider round">
                {/* <img alt="test" src="images/icons/timer.svg" className="light-mode-img" />
                <img alt="test" src="images/icons/timer.svg" className="dark-mode-img" /> */}
              </span>
            </label>

          </div>
        </div>
      </div>
    );
  }
}

const AppWitRouterAndCookies = withCookies(AppWithRouter)

const AppContainer = () => (
  <Router>
    <AppWitRouterAndCookies />
  </Router>
);

export default AppContainer;
